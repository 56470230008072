import { useEffect, useState } from 'react';

const useWindowDimensions = () => {
  if (typeof window === 'undefined')
    return {
      width: undefined,
      height: undefined
    };

  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
