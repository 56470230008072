import typeOf from 'typeof';

export const toMoney = (data) => {
  if (!data) return '0';

  return `${data.toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')}`;
};

export const toNumber = (data) => {
  if (!data) return '';

  return Number(data.toString().replace(/[^0-9.]/g, ''));
};

export const moneyText = (money) => {
  const moneyNumber = Number(money);

  if (moneyNumber < 1000) return `${Number((moneyNumber).toFixed(1))} ₽`;
  if (moneyNumber < 1000000) return `${Number((moneyNumber / 1000).toFixed(1))} тыс ₽`;
  if (moneyNumber < 1000000000) return `${Number((moneyNumber / 1000000).toFixed(1))} млн ₽`;

  return `${Number((moneyNumber / 1000000000).toFixed(1))} млрд ₽`;
};

export const declOfNum = (n, titles) => {
  return titles[
    // eslint-disable-next-line no-nested-ternary
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];
};

export const spaceSplitter = (num, it) => {
  if (!num) return num;

  const reg = new RegExp(`(?!^)(?=(?:\\d{${it}})+(?:\\.|$))`, 'gm');

  return num.toString().replace(reg, ' ');
};

export const randomIntFromInterval = (min, max) => {
  if (!min || !max) return 0;

  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const JSONToQuery = (j, i = [], r = []) => {
  /** First - converting to objects keys.
   *  Second - removing empty array, empty strings and els from ignore list.
   *  Third - converting into string "key=value"
   *  Fourth - removing hidden keys.
   *  Fifth - creating a string.
   */
  const jtq = Object.keys(j)
    .filter((x) => !i.includes(x) && ((j[x] && j[x].length > 0) || (Number.isInteger(j[x]) && j[x] > 0)))
    .map((k) => `${k}=${typeOf(j[k]) === 'array' ? JSON.stringify(j[k]) : j[k]}`)
    .filter((z) => !r.includes(z))
    .join('&');

  return `${jtq.length > 0 ? '?' : ''}${jtq}`;
};

export const phoneToString = (p) => {
  if (!p || p.length !== 11)
    return p || '';

  return `+${p.slice(0, 1)} ${p.slice(1, 4)} ${p.slice(4, 7)} ${p.slice(7, 9)} ${p.slice(9, 11)}`;
};

export const copyToClipboard = (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const getPriceDiff = (t, p) => {
  if (!t || !p.value || !p.old)
    return false;

  if ((t === 'продажа' && Math.abs(p.value - p.old) >= 10000) || (t === 'аренда' && Math.abs(p.value - p.old) >= 1000)) {
    return {
      value: p.old,
      icon: p.value - p.old > 0 ? (
        <div style={{ color: '#ff8c8c' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="19" x2="12" y2="5"/><polyline points="5 12 12 5 19 12"/></svg>
        </div>
      ) : (
        <div style={{ color: '#90e184' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"/><polyline points="19 12 12 19 5 12"/></svg>
        </div>
      )
    };
  }

  return false;
};

export const getTimeInterval = (minutes) => {
  if (!minutes)
    return false;

  if (minutes <= 0)
    minutes = 1;

  if (minutes / 60 < 1) {
    return `${minutes % 60} ${declOfNum(minutes % 60, [
      'минуту',
      'минуты',
      'минут'
    ])} назад`;
  }
  if (minutes / 60 / 24 < 1) {
    return `${Math.floor(minutes / 60)} ${declOfNum(Math.floor(minutes / 60), [
      'час',
      'часа',
      'часов'
    ])} назад`;
  }

  return `${Math.floor(minutes / 60 / 24)} ${declOfNum(Math.floor(minutes / 60 / 24), [
    'день',
    'дня',
    'дней'
  ])} назад`;
};

export const quarterConverter = (q) => {
  switch (q) {
    case '1':
      return 'I';

    case '2':
      return 'II';

    case '3':
      return 'III';

    case '4':
      return 'IV';

    default:
      return '';
  }
};

export const valueToZero = (v) => {
  if (typeOf(v) === 'array')
    return [];
  if (typeOf(v) === 'object')
    return {};
  if (typeOf(v) === 'number')
    return 0;
  if (typeOf(v) === 'boolean')
    return false;

  return '';
};

export const cloneObject = (j) => {
  return JSON.parse(JSON.stringify(j));
};

export const categoryCaseConverter = (category, count = 0) => {
  let readyCase = '';

  switch (category) {
    case 'квартира':
      readyCase = declOfNum(count, ['квартира', 'квартиры', 'квартир']);
      break;

    case 'комната':
      readyCase = declOfNum(count, ['комната', 'команты', 'комнат']);
      break;

    case 'дом':
      readyCase = declOfNum(count, ['дом', 'дома', 'домов']);
      break;

    case 'участок':
      readyCase = declOfNum(count, ['участок', 'участка', 'участков']);
      break;

    case 'коммерция':
      readyCase = declOfNum(count, ['коммерция', 'коммерции', 'коммерции']);
      break;

    default:
      readyCase = declOfNum(count, ['вариант', 'варианта', 'вариантов']);
      break;
  }

  return readyCase;
};

export default {
  toMoney,
  toNumber,
  moneyText,
  declOfNum,
  spaceSplitter,
  randomIntFromInterval,
  capitalizeFirstLetter,
  sleep,
  JSONToQuery,
  phoneToString,
  copyToClipboard,
  getPriceDiff,
  getTimeInterval,
  quarterConverter,
  valueToZero,
  cloneObject,
  categoryCaseConverter
};
