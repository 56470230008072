import { createContext, useContext, useReducer } from 'react';
import { useRouter } from 'next/router';
import { cloneObject } from '../helpers';

const NewsStateContext = createContext();
const NewsDispatchContext = createContext();

const initialState = {
  filter: {
  },
  sorting: {
    sort: 'updated_at',
    sortDirection: 'desc'
  },
  pagination: {
    page: 1,
    length: 6
  },
};

const NewsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      return { ...state, filter: { ...initialState.filter, ...action.payload } };

    case 'SET_SORTING':
      return { ...state, sorting: { ...initialState.sorting, ...action.payload } };

    case 'SET_PAGINATION':
      return { ...state, pagination: { ...initialState.pagination, ...action.payload } };

    case 'SET_STATE':
      return { ...state, state: { ...initialState.state, ...action.payload } };

    default: {
      return state;
    }
  }
};

export const NewsProvider = ({ children }) => {
  const { pathname, query } = useRouter();
  const [state, dispatch] = useReducer(NewsReducer, cloneObject(initialState), (state) => {
    /** PAGINATION */
    if (query.page) state.pagination.page = query.page;
    /** SORTING */
    if (query.sort) state.sorting.sort = query.sort;
    if (query.sortDirection) state.sorting.sortDirection = query.sortDirection;

    return state;
  });

  return (
    <NewsStateContext.Provider value={state}>
      <NewsDispatchContext.Provider value={dispatch}>
        {children}
      </NewsDispatchContext.Provider>
    </NewsStateContext.Provider>
  );
};

export const useNewsState = () => {
  const context = useContext(NewsStateContext);

  if (context === undefined) {
    throw new Error('useNewsState must be used within a NewsProvider');
  }

  return context;
};

export const useNewsDispatch = () => {
  const context = useContext(NewsDispatchContext);

  if (context === undefined) {
    throw new Error('useNewsDispatch must be used within a NewsProvider');
  }

  return context;
};

export const useNewsProvider = () => {
  return [useNewsState(), useNewsDispatch()];
};
