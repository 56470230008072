import { createContext, useContext, useReducer } from 'react';
import { useRouter } from 'next/router';
import { cloneObject } from '../helpers';

const BuildingsStateContext = createContext();
const BuildingsDispatchContext = createContext();

const initialState = {
  filter: {
    rooms: [],
    studio: 0,
    priceFrom: '',
    priceTo: '',
    squareFrom: '',
    squareTo: '',
    squareLivingFrom: '',
    squareLivingTo: '',
    squareKitchenFrom: '',
    squareKitchenTo: '',
    floorFrom: '',
    floorTo: '',
    floorsFrom: '',
    floorsTo: '',
    ceiling: '',
    deadlineYear: '',
    deadlineQuartal: '',
    renovation: [],
    buildingType: [],
    balcony: [],
    bathroom: []
  },
  sorting: {
    sort: 'price.value',
    sortDirection: 'asc'
  },
  pagination: {
    page: 1,
    length: 5
  },
  state: {
    isFiltersMobileVisible: false
  }
};

const BuildingsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      return { ...state, filter: { ...initialState.filter, ...action.payload } };

    case 'SET_SORTING':
      return { ...state, sorting: { ...initialState.sorting, ...action.payload } };

    case 'SET_PAGINATION':
      return { ...state, pagination: { ...initialState.pagination, ...action.payload } };

    case 'SET_STATE':
      return { ...state, state: { ...initialState.state, ...action.payload } };

    default: {
      return state;
    }
  }
};

export const BuildingsProvider = ({ children }) => {
  const { pathname, query } = useRouter();
  const [state, dispatch] = useReducer(BuildingsReducer, cloneObject(initialState), (state) => {
    if (!pathname.startsWith('/[region]/buildings'))
      return state;

    /** ROOMS */
    if (query.studio || query.rooms) {
      if (query.rooms) state.filter.rooms = JSON.parse(query.rooms);
      if (query.studio) state.filter.studio = 1;
    }
    /** PRICE */
    if (query.priceFrom) state.filter.priceFrom = query.priceFrom;
    if (query.priceTo) state.filter.priceTo = query.priceTo;
    /** SQUARE */
    if (query.squareFrom) state.filter.squareFrom = query.squareFrom;
    if (query.squareTo) state.filter.squareTo = query.squareTo;
    /** LIVING SQUARE */
    if (query.squareLivingFrom) state.filter.squareLivingFrom = query.squareLivingFrom;
    if (query.squareLivingTo) state.filter.squareLivingTo = query.squareLivingTo;
    /** KITCHEN SQUARE */
    if (query.squareKitchenFrom) state.filter.squareKitchenFrom = query.squareKitchenFrom;
    if (query.squareKitchenTo) state.filter.squareKitchenTo = query.squareKitchenTo;
    /** FLOOR */
    if (query.floorFrom) state.filter.floorFrom = query.floorFrom;
    if (query.floorTo) state.filter.floorTo = query.floorTo;
    /** FLOORS */
    if (query.floorsFrom) state.filter.floorsFrom = query.floorsFrom;
    if (query.floorsTo) state.filter.floorsTo = query.floorsTo;
    /** CEILING */
    if (query.ceiling) state.filter.ceiling = query.ceiling;
    /** RENOVATION */
    if (query.renovation) state.filter.renovation = JSON.parse(query.renovation);
    /** BATHROOM */
    if (query.bathroom) state.filter.bathroom = JSON.parse(query.bathroom);
    /** BALCONY */
    if (query.balcony) state.filter.balcony = JSON.parse(query.balcony);
    /** BUILDING TYPE */
    if (query.buildingType) state.filter.buildingType = JSON.parse(query.buildingType);
    /** DEADLINE YEAR */
    if (query.deadlineYear) state.filter.deadlineYear = query.deadlineYear;
    /** DEADLINE QUARTAL */
    if (query.deadlineQuartal) state.filter.deadlineQuartal = query.deadlineQuartal;
    /** PAGINATION */
    if (query.page) state.pagination.page = query.page;
    /** SORTING */
    if (query.sort) state.sorting.sort = query.sort;
    if (query.sortDirection) state.sorting.sortDirection = query.sortDirection;

    return state;
  });

  return (
    <BuildingsStateContext.Provider value={state}>
      <BuildingsDispatchContext.Provider value={dispatch}>
        {children}
      </BuildingsDispatchContext.Provider>
    </BuildingsStateContext.Provider>
  );
};

export const useBuildingsState = () => {
  const context = useContext(BuildingsStateContext);

  if (context === undefined) {
    throw new Error('useBuildingsState must be used within a BuildingsProvider');
  }

  return context;
};

export const useBuildingsDispatch = () => {
  const context = useContext(BuildingsDispatchContext);

  if (context === undefined) {
    throw new Error('useBuildingsDispatch must be used within a BuildingsProvider');
  }

  return context;
};

export const useBuildingsProvider = () => {
  return [useBuildingsState(), useBuildingsDispatch()];
};
