import { createContext, useContext, useReducer, useEffect } from 'react';
import { cloneObject } from '../helpers';

const AppStateContext = createContext();
const AppDispatchContext = createContext();

const initialState = { history: [], favorites: [], isLoaded: false };

const AppReducer = (state, action) => {
  switch (action.type) {
    case 'SET_APP':
      return {
        ...initialState,
        ...action.payload
      };

    default: {
      return state;
    }
  }
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, cloneObject(initialState));

  useEffect(() => {
    if (!localStorage.getItem('history')) localStorage.setItem('history', JSON.stringify([]));
    if (!localStorage.getItem('favorites')) localStorage.setItem('favorites', JSON.stringify([]));

    dispatch({
      type: 'SET_APP',
      payload: {
        history: JSON.parse(localStorage.getItem('history')),
        favorites: JSON.parse(localStorage.getItem('favorites')),
        isLoaded: true
      }
    });
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  const context = useContext(AppStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }

  return context;
};

export const useAppDispatch = () => {
  const context = useContext(AppDispatchContext);

  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }

  return context;
};

export const useAppProvider = () => {
  return [useAppState(), useAppDispatch()];
};
