import Script from 'next/script';
import { Fragment } from 'react';

const Metrics = () => {
  return process.env.NODE_ENV === 'production' && typeof window !== 'undefined' ? (
    <Fragment>
      <Script strategy="afterInteractive" id="yandex">
        {`
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          var z = null;m[i].l=1*new Date();
          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(90877061, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true
          });
        `}
      </Script>
    </Fragment>
  ) : null;
};

export default Metrics;
