import { useEffect } from 'react';
import { AppProvider } from '../context/app';
import { LocationProvider } from '../context/location';
import { ObjectsProvider } from '../context/objects';
import { BuildingsProvider } from '../context/buildings';
import { NewsProvider } from '../context/news';
import { MortgageProvider } from '../context/mortgage';
import { YMaps } from 'react-yandex-maps';
import Metrics from '../components/Metrics';
import useWindowDimensions from '../hooks/useWindowDimensions';

import '../styles/globals.scss';
import Head from 'next/head';

const App = ({ Component, pageProps }) => {
  const { height } = useWindowDimensions();

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }, [height]);

  return (
    <AppProvider>
      <LocationProvider>
        <ObjectsProvider>
          <BuildingsProvider>
            <MortgageProvider>
              <NewsProvider>
                <YMaps>
                  <Head>
                    <meta name="application-name" content="База недвижимости" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                    <meta name="apple-mobile-web-app-title" content="База недвижимости" />
                    <meta name="format-detection" content="telephone=no" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="theme-color" content="#113376" />
                    <meta name="viewport" content="width=device-width, initial-scale=0.9" />
                    <link rel="manifest" href="/manifest.json" />
                    <link rel="icon" href="/icon.png" />
                    <link rel="apple-touch-icon" href="/images/icon.png" />
                    <script
                      type="application/ld+json"
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                          '@context': 'https://schema.org',
                          '@type': 'Organization',
                          url: `https://${process.env.NEXT_PUBLIC_SITEDOMAIN}`,
                          logo: `https://${process.env.NEXT_PUBLIC_SITEDOMAIN}/images/icon.png`
                        })
                      }}
                    />
                  </Head>
                  <Component {...pageProps} />
                  <Metrics />
                </YMaps>
              </NewsProvider>
            </MortgageProvider>
          </BuildingsProvider>
        </ObjectsProvider>
      </LocationProvider>
    </AppProvider>
  );
};

export default App;
