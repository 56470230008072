import { createContext, useContext, useReducer, useEffect } from 'react';
import location from '../data/location.json';
import { cloneObject } from '../helpers';
import axios from 'axios';
import { useRouter } from 'next/router';


const LocationStateContext = createContext();
const LocationDispatchContext = createContext();

const initialState = {
  code: '',
  region: '',
  name: '...',
  case: '...',
  coordinates: [47.222078, 39.720358],
  phone: '...',
  phoneURI: '...',
  zoom: 13,
  isDefault: true,
  isLoaded: false,
  seo: {
    title: '',
    description: "",
    url: "",
    sitename: "",
    footer: ""
  }
};

const LocationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return {
        ...initialState,
        ...action.payload
      };

    default: {
      return state;
    }
  }
};

export const LocationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocationReducer, cloneObject(initialState));
  const router = useRouter();

  let defaultRegion = '';

  useEffect(() => {    
    if(router && router.query && router.query.region && router.query.region.length > 3 ) 
        defaultRegion = location.filter((v) => v.code === router.query.region);        
      
    axios.get('https://api.sypexgeo.net/2Ct1F/json/').then((response) => {
      let data = response.data;
      console.log(data);

      if(data && data.region && data.region.name_ru && data.ip) {
        try {
          axios.get(process.env.NEXT_PUBLIC_API + '/stat/region?ip='+ data.ip +'&region=' + data.region.name_ru);
        }
        catch (error) {
          //console.log(error);
        }
      }
    }).catch((error) => {
      //console.log(error);
    });

    if (defaultRegion.length === 0)
      defaultRegion = location.filter((v) => v.code === localStorage.getItem('location'));

    if (defaultRegion.length === 0)
      defaultRegion = location.filter((v) => v.isDefault === true);

    dispatch({
      type: 'SET_LOCATION',
      payload: { ...location.filter((v) => v.code === defaultRegion[0].code)[0], isLoaded: true }
    });

  }, []);

  return (
    <LocationStateContext.Provider value={state}>
      <LocationDispatchContext.Provider value={dispatch}>
        {children}
      </LocationDispatchContext.Provider>
    </LocationStateContext.Provider>
  );
};

export const useLocationState = () => {
  const context = useContext(LocationStateContext);

  if (context === undefined) {
    throw new Error('useLocationState must be used within a LocationProvider');
  }

  return context;
};

export const useLocationDispatch = () => {
  const context = useContext(LocationDispatchContext);

  if (context === undefined) {
    throw new Error('useLocationDispatch must be used within a LocationProvider');
  }

  return context;
};

export const useLocationProvider = () => {
  return [useLocationState(), useLocationDispatch()];
};
